<template>
  <v-dialog
    v-model="dialog"
    persistent
    keydown
    max-width="800px"
    transition="dialog-bottom-transition"
    content-class="vdialognew"
  >
    <v-container
      fill-height
      fluid
    >
      <v-card
        class="pa-3"
        width="100%"
      >
        <v-toolbar
          flat
          width="100%"
          :rounded="false"
        >
          <v-toolbar-title>Add new member to group: {{ group.groupName }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="close()"
              @mouseover="hoverCloseButtonIcon = true"
              @mouseleave="hoverCloseButtonIcon = false"
            >
              <v-icon
                v-if="hoverCloseButtonIcon"
                small
              >
                mdi-close
              </v-icon>
              <v-icon
                v-else
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row
          v-for="(input, index) in memberFields"
          :key="`memberInput-${index}`"
          class="mt-2"
        >
          <v-col cols="12">
            <v-select
              v-model="input.member"
              :items="existingMembers"
              item-text="email"
              label="Choose member"
              hint="required field"
              persistent-hint
              return-object
              :rules="[(v) => !!v || 'Member required']"
              dense
              outlined
              class="mx-6"
            />
          </v-col>
        </v-row>
        <v-alert
          v-if="memberFields.length"
          border="left"
          colored-border
          type="warning"
          elevation="2"
          class="mt-2 mx-5"
        >
          Please note that all company members with "admin" and "admin(Owner)" user role are automatically participating in any group.
        </v-alert>
        <v-card-actions class="mt-1">
          <v-btn
            v-if="groupMembersAmountRule || company.members.length === group.groupMembers.length"
            disabled
            rounded
            outlined
            small
            color="primary"
            class="mb-2"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-account-plus
            </v-icon>
            Invite new member
          </v-btn>
          <v-btn
            v-else
            rounded
            outlined
            small
            color="primary"
            class="mb-2"
            @click="addField()"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-account-plus
            </v-icon>
            Invite new member
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            rounded
            dense
            min-width="150"
            :disabled="restrictionRule === false"
            :loading="loading"
            @click="submit"
          >
            {{ $t('actions|submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    group: {
      type: Object,
      default: null
    },
    groupId: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      hoverCloseButtonIcon: false,
      loading: false,
      error: null,
      memberFields: [],
      restrictionRule: false,
      groupMembersAmountRule: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    existingMembers () {
      let members = []
      let member = {}
      for (let i = 0; i < this.company.members.length; i++) {
        member = this.company.members[i]
        const foundRegularUserRole = this.company.userRoles.basic.findIndex(id => id === member.member_id._id)
        const foundAdvancedUserRole = this.company.userRoles.advanced.findIndex(ur => ur === member.member_id._id)
        if (foundRegularUserRole !== -1) {
          members.push(member)
        } else if (foundAdvancedUserRole !== -1) {
          members.push(member)
        }
      }
      if (this.group && this.group.groupMembers && this.group.groupMembers.length) {
        for (let i = 0; i < this.group.groupMembers.length; i++) {
          const actualMember = this.group.groupMembers[i]
          const findMatchIndex = members.findIndex(m => m.member_id._id === actualMember._id)
          if (findMatchIndex !== -1) {
            members.splice(findMatchIndex, 1)
          }
        }
      }
      return members
    },
    adminsInMembers () {
      let members = []
      for (let i = 0; i < this.company.members.length; i++) {
        const member = this.company.members[i]
        const foundAminUserRole = this.company.userRoles.admin.findIndex(id => id === member.member_id._id)
        const foundAdminOwnerUserRole = this.company.userRoles.adminOwner.findIndex(ur => ur === member.member_id._id)
        if (foundAminUserRole !== -1) {
          members.push(member)
        } else if (foundAdminOwnerUserRole !== -1) {
          members.push(member)
        }
      }
      return members
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    memberFields: {
      handler (value) {
        this.restrictionRule = value.every(f => f.member.member_id)
        if (value.length === 0) {
          this.restrictionRule = false
          return this.restrictionRule
        }
        if (value.length >= this.company.members.length - this.group.groupMembers.length) {
          this.groupMembersAmountRule = true
          return this.groupMembersAmountRule
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      addMemberToGroup: 'company/addMemberToGroup'
    }),
    addField () {
      this.memberFields.push({
        member: {}
      })
    },
    removeField (index) {
      this.memberFields.splice(index, 1)
    },
    openDialog (value) {
      this.addField()
      this.dialog = value
    },
    reset () {
      this.memberFields = []
      this.restrictionRule = false
      this.groupMembersAmountRule = false
    },
    close () {
      this.reset()
      this.dialog = false
    },
    async submit () {
      this.loading = true
      const _id = this.company._id
      const groupId = this.groupId
      const payload = {
        members: this.memberFields
      }
      try {
        await this.addMemberToGroup({ _id, groupId, payload })
        this.dialog = false
        this.reset()
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.vdialognew {
  box-shadow: none !important;
}
</style>
