<template>
  <v-container
    fluid
    class="pa-0"
    :style="`max-height: ${($vuetify.breakpoint.height - 130).toString() + 'px'}; overflow-y: auto;`"
  >
    <v-container
      fluid
      class="px-3 mt-3"
      style="position: sticky; top: 15px; z-index: 5;"
    >
      <v-row
        class="mt-n7"
        justify="start"
        align="center"
      >
        <v-toolbar
          flat
          color="lightBackground"
          style="width: 100%;"
        >
          <v-text-field
            v-model="search"
            :solo-inverted="soloInverted"
            :solo="solo"
            prepend-inner-icon="mdi-magnify"
            rounded
            :label="$t('groups|search')"
            dense
            class="ml-4 shrink"
            hide-details
            @focus="soloInverted = true; solo = false"
            @blur="soloInverted = false; solo = true"
          />          <v-spacer />
          <v-btn
            v-if="permissionRuleCreateGroup()"
            color="primary"
            rounded
            small
            class="mr-4"
            @click="openCreateGroupDialog()"
          >
            <v-icon
              left
              dark
            >
              mdi-account-multiple-plus
            </v-icon>
            {{ $t('groups|create') }}
          </v-btn>
        </v-toolbar>
      </v-row>
    </v-container>
    <CreateGroup
      ref="CreateGroup"
    />
    <div v-if="company.groups && company.groups.length">
      <v-card
        v-for="(group, index) in filteredGroups"
        :key="group._id"
        elevation="5"
        class="my-6 mx-4"
      >
        <v-card-title
          v-if="company"
        >
          <v-icon
            size="30"
            left
            color="primary"
            class="mt-n5"
          >
            mdi-account-supervisor-circle
          </v-icon>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-hover
                v-slot="{ hover }"
              >
                <v-text-field
                  v-model="group.groupName"
                  solo
                  :solo-inverted="hover || flatFields.includes(index) ? true : false"
                  :flat="hover || flatFields.includes(index) ? false : true"
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @focus="flatFields.push(index)"
                  @blur="updateName(group); flatFields.splice(0, 1)"
                />
              </v-hover>
            </template>
            <span>{{ $t('groups|change_name') }}</span>
          </v-tooltip>
          <v-spacer />
          <v-menu
            :close-on-content-click="true"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="mt-n10"
                v-on="on"
              >
                mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <v-tooltip
                top
                :disabled="!permissionRuleAdmin(account._id)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item
                      :disabled="permissionRuleAdmin(account._id)"
                      @click="addNewMember(index)"
                    >
                      <v-list-item-icon>
                        <v-icon>
                          mdi-account-plus-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        class="ml-n5"
                      >
                        {{ $t('members|add_member') }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </template>
                <span>{{ $t('groups|new_members_create_permission') }}</span>
              </v-tooltip>

              <v-tooltip
                bottom
                :disabled="!permissionRuleAdmin(account._id)"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item
                      :disabled="permissionRuleAdmin(account._id)"
                      @click="deleteGroup(group._id)"
                    >
                      <v-list-item-icon>
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        class="ml-n5"
                      >
                        {{ $t('groups|delete') }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </template>
                <span>{{ $t('groups|delete_permission') }}</span>
              </v-tooltip>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-subtitle>
          <div class="row mt-n8">
            <v-icon
              color="primary"
              size="25"
              class="ml-3 mt-n6"
            >
              mdi-email
            </v-icon>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover
                  v-slot="{ hover }"
                >
                  <v-text-field
                    v-model="group.groupEmail"
                    solo
                    :solo-inverted="hover || flatFields.includes(index) ? true : false"
                    :flat="hover || flatFields.includes(index) ? false : true"
                    dense
                    v-bind="attrs"
                    class="ml-4"
                    style="max-width: 500px;"
                    v-on="on"
                    @focus="flatFields.push(index)"
                    @blur="updateEmail(group); flatFields.splice(0, 1)"
                  />
                </v-hover>
              </template>
              <span>{{ $t('groups|change_email') }}</span>
            </v-tooltip>
          </div>
        </v-card-subtitle>
        <AddGroupMember
          ref="groupmember"
          :group="group"
          :group-id="group._id"
          @closeNewMemberDialog="dialog = false"
        />
        <v-data-table
          :headers="tableHeaders"
          :items="filteredGroupMembers(group)"
          :loading="loading"
          :loading-text="$t('loading|please_wait')"
        >
          <template v-slot:item.accountData.accountName="{ item }">
            <v-avatar
              size="35"
              color="primary"
              class="mx-4 my-2"
            >
              <img
                v-if="item && item.avatar && Object.keys(item.avatar).length !== 0"
                :src="`${cfg.baseURL}downloadAvatar/${item._id}/${item.avatar.raw.filename}`"
              >
              <v-icon
                v-else
                size="30"
                dark
              >
                mdi-account-circle
              </v-icon>
            </v-avatar>
            {{ item.accountData.firstName && item.accountData.lastName ? item.accountData.firstName + ' ' + item.accountData.lastName : item.accountName }}
          </template>
          <template v-slot:item.currentUserRole="{ item }">
            <v-chip
              v-if="item.currentUserRole === 'Admin' || item.currentUserRole === 'Admin(Owner)'"
              small
              color="primary"
            >
              {{ translateUserRole(item.currentUserRole) }}
            </v-chip>
            <v-chip
              v-else
              small
              color="secondary"
            >
              {{ translateUserRole(item.currentUserRole) }}
            </v-chip>
          </template>
          <template
            v-if="permissionRule()"
            v-slot:item.actions="{ item }"
          >
            <v-icon
              v-if="userRoleFilter(item)"
              @click="deleteThisGroupMember(group, item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-container
      v-else
    >
      {{ $t('groups|no_current_groups') }}
    </v-container>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { mdiAccountConvertOutline } from '@mdi/js'
import AddGroupMember from '../../components/dialogs/AddGroupMember.vue'
import cfg from '@/config'
import CreateGroup from '../../components/dialogs/CreateGroup.vue'

const createdAtFormat = date => moment(date).format('DD/MM/YYYY')

export default {
  components: {
    AddGroupMember,
    CreateGroup
  },
  data () {
    return {
      accounts: [],
      loading: false,
      error: null,
      search: '',
      userRoles: [
        'Regular', 'Advanced', 'Admin'
      ],
      roleSelection: '',
      accountIcon: mdiAccountConvertOutline,
      memberFields: [],
      flatFields: [],
      calcWidth: '',
      cfg,
      soloInverted: false,
      solo: true
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    filteredGroups () {
      let res
      if (this.search && this.search.length) res = this.company.groups.filter((gr) => { if (gr.groupName.includes(this.search)) return gr })
      else res = this.company.groups
      return res
    },
    tableHeaders () {
      const headers = [
        {
          text: this.$t('members|members'),
          align: 'start',
          value: 'accountData.accountName'
        },
        // {
        //   text: 'Date added',
        //   align: 'start',
        //   value: 'createdAt'
        // },
        {
          text: this.$t('members|user_role'),
          align: 'start',
          value: 'currentUserRole'
        }
      ]
      if (this.company && ((this.company.userRoles.advanced.includes(this.account._id)) || (this.company.userRoles.adminOwner.includes(this.account._id)) || this.company.userRoles.admin.includes(this.account._id))) {
        headers.push({ text: this.$t('actions|actions'), value: 'actions', sortable: false, align: 'end' })
      }
      return headers
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      deleteThisMember: 'company/deleteMember',
      deleteGroupMember: 'company/deleteGroupMember',
      deleteActualGroup: 'company/deleteActualGroup',
      updateGroupName: 'company/updateGroupName',
      updateGroupEmail: 'company/updateGroupEmail'
    }),
    translateUserRole (input) {
      if (input === 'Admin') return this.$t('members|admin')
      if (input === 'Admin(Owner)') return this.$t('members|admin_owner')
      if (input === 'Regular') return this.$t('members|regular')
      if (input === 'Advanced') return this.$t('members|advanced')
    },
    openCreateGroupDialog () {
      this.$refs.CreateGroup.dialog = true
    },
    permissionRuleCreateGroup () {
      if (this.company) {
        if (this.company.subscriptionStatus === 'unpaid') {
          return false
        }
        const searchResult = this.company.userRoles.adminOwner.includes(this.account._id)
        if (searchResult) {
          return searchResult
        }
        const secondSearchResult = this.company.userRoles.admin.includes(this.account._id)
        if (secondSearchResult) {
          return secondSearchResult
        }
      }
    },
    filteredGroupMembers (group) {
      return group.groupMembers.map((m) => {
        const member = {
          ...m,
          updatedAt: createdAtFormat(m.updatedAt),
          createdAt: createdAtFormat(m.createdAt),
          currentUserRole: this.rewriteCurrentRole(m.currentUserRole)
        }
        return member
      })
    },
    rewriteCurrentRole (role) {
      if (role === 'adminOwner') {
        return 'Admin(Owner)'
      } else {
        return role
      }
    },
    permissionRule () {
      const searchResult = this.company.userRoles.adminOwner.includes(this.account._id)
      const secondSearchResult = this.company.userRoles.admin.includes(this.account._id)
      const thirdSearchResult = this.company.userRoles.advanced.includes(this.account._id)
      if (searchResult) {
        return searchResult
      }
      if (secondSearchResult) {
        return secondSearchResult
      }
      if (thirdSearchResult) {
        return thirdSearchResult
      }
    },
    permissionRuleAdmin (accId) {
      if (this.company) {
        const searchResult = this.company.userRoles.adminOwner.find(id => id === accId)
        const secondSearchResult = this.company.userRoles.admin.find(id => id === accId)
        if (searchResult || secondSearchResult) {
          return false
        } else {
          return true
        }
      }
    },
    userRoleFilter (member) {
      if (this.company) {
        const foundRegularRole = this.company.userRoles.basic.includes(member._id)
        if (foundRegularRole) {
          return foundRegularRole
        }
        const foundAdvancedRole = this.company.userRoles.advanced.includes(member._id)
        if (foundAdvancedRole) {
          return foundAdvancedRole
        }
      }
    },
    addNewMember (index) {
      this.$refs.groupmember[index].openDialog(true)
    },
    async deleteGroup (id) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('groups|delete_are_you_sure')
      })
      const _id = id
      if (res) {
        try {
          await this.deleteActualGroup({ _id })
        } catch (e) {
          console.error(e, 'error')
        }
      }
    },
    async deleteThisGroupMember (group, member) {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('members|warning')
      })
      const groupId = group._id
      const _id = member._id
      if (res) {
        try {
          await this.deleteGroupMember({ _id, groupId })
        } catch (e) {
          console.error(e, 'error')
        }
      }
    },
    async updateName (group) {
      const payload = {
        newGroupName: group.groupName
      }
      await this.updateGroupName({ _id: this.company._id, groupId: group._id, payload })
    },
    async updateEmail (group) {
      const payload = {
        newGroupEmail: group.groupEmail
      }
      await this.updateGroupEmail({ _id: this.company._id, groupId: group._id, payload })
    }
  }
}
</script>

<style lang="scss" scoped>
  .members-table-wrapper {
    width: 100%;
  }
</style>
