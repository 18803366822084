<template>
  <v-dialog
    v-model="dialog"
    persistent
    keydown
    width="800px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        flat
        class="general-form-toolbar"
        width="100%"
        :rounded="false"
      >
        <v-toolbar-title>{{ $t('groups|create') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          rounded
          class="mr-1"
          @click="close()"
          @mouseover="hoverCloseButtonIcon = true"
          @mouseleave="hoverCloseButtonIcon = false"
        >
          <v-icon
            :small="hoverCloseButtonIcon"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-text-field
        v-model="groupData.groupName"
        outlined
        dense
        required
        :rules="[(v) => !!v || $t('fields|required')]"
        :label="$t('groups|name')"
        class="mt-4 mx-6"
      />
      <v-text-field
        v-model="groupData.groupEmail"
        outlined
        dense
        :label="$t('groups|email')"
        append-icon="mdi-information-variant"
        class="mt-2 mx-6"
      >
        <template v-slot:append>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-variant
              </v-icon>
            </template>
            <v-card
              class="pa-4"
              max-width="400"
            >
              {{ $t('groups|email_warning') }}
            </v-card>
          </v-menu>
        </template>
      </v-text-field>
      <v-row
        v-for="(input, index) in memberFields"
        :key="`memberInput-${index}`"
        class="mx-6 my-2"
      >
        <v-select
          v-model="memberFields[index]"
          :items="existingMembers"
          item-text="email"
          :label="$t('actions|choose_member')"
          :hint="$t('fields|required')"
          persistent-hint
          return-object
          :rules="[(v) => !!v || 'Member required']"
          dense
          outlined
        >
          <template v-slot:item="{ on, item }">
            <v-list-item
              :disabled="disableItem(item)"
              v-on="on"
            >
              {{ item.email }}
            </v-list-item>
          </template>
        </v-select>
        <v-btn
          icon
          class="ml-2"
          @click="removeField(index)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-row>
      <v-card-actions>
        <v-btn
          rounded
          outlined
          small
          :disabled="groupMembersAmountRule"
          color="primary"
          class="mb-2"
          @click="addField()"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-account-plus
          </v-icon>
          {{ $t('members|invite_new_member') }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="restrictionRule"
          color="primary"
          small
          rounded
          min-width="150"
          :loading="loading"
          @click="submit"
        >
          {{ $t('actions|submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { EventBus } from '@/utils/EventBus'

export default {
  data () {
    return {
      dialog: false,
      hoverCloseButtonIcon: false,
      groupData: {
        groupName: '',
        groupEmail: ''
      },
      loading: false,
      error: null,
      memberFields: [],
      restrictionRule: true,
      groupMembersAmountRule: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company
    }),
    existingMembers () {
      let members = []
      for (let i = 0; i < this.company.members.length; i++) {
        const member = this.company.members[i]
        const adminOwnerIndex = this.company.userRoles.adminOwner.findIndex(id => id === member.member_id._id)
        const adminIndex = this.company.userRoles.admin.findIndex(id => id === member.member_id._id)
        if (adminOwnerIndex === -1 && adminIndex === -1) {
          members.push(member)
        }
      }
      return members
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    memberFields: {
      handler (value) {
        if (value) {
          const admins = this.company.userRoles.adminOwner.length + this.company.userRoles.admin.length
          if (this.existingMembers.length + admins + value.length >= this.company.seats) {
            this.groupMembersAmountRule = true
          } else {
            this.groupMembersAmountRule = false
          }
          this.restrictionRule = !(this.groupData.groupName.length !== 0 && this.memberFields.length !== 0 && this.memberFields.every(f => Object.keys(f).length > 2))
          if (!value.length) {
            if (this.groupData && this.groupData.groupName.length) {
              this.restrictionRule = false
            } else if (this.groupData === null) {
              this.restrictionRule = true
            }
            // if (this.groupData && this.groupData.groupName.length && this.groupData.groupEmail.length > 0) {
            //   this.restrictionRule = false
            // } else if (this.groupData.groupName.length <= 0 || this.groupData.groupEmail.length <= 0) {
            //   this.restrictionRule = true
            // } else if (this.groupData === null) {
            //   this.restrictionRule = true
            // }
          }
        }
      },
      deep: true
    },
    groupData: {
      handler (value) {
        if (value && value.groupName.length) {
          this.restrictionRule = false
        } else if (value === null) {
          this.restrictionRule = true
        }
      },
      deep: true
    }
  },
  created () {
    EventBus.$on('dialog-create-group', this.onCreateGroupEvent)
  },
  beforeDestroy () {
    EventBus.$off('dialog-create-group', this.onCreateGroupEvent)
  },
  methods: {
    ...mapActions({
      createGroup: 'company/createGroup'
    }),
    addField () {
      this.memberFields.push({
        member_id: {}
      })
    },
    removeField (index) {
      this.memberFields.splice(index, 1)
    },
    disableItem (item) {
      const allreadyInvitedMember = this.memberFields.findIndex(mem => mem.member_id._id === item.member_id._id)
      if (allreadyInvitedMember !== -1) return true
      else return false
    },
    onCreateGroupEvent (event) {
      this.dialog = true
    },
    reset () {
      this.groupData.groupName = ''
      this.groupData.groupEmail = ''
      this.groupMembersAmountRule = false
      this.restrictionRule = false
      this.memberFields = []
    },
    close () {
      this.reset()
      this.dialog = false
    },
    async submit () {
      this.loading = true
      const _id = this.company._id
      const payload = {
        groupName: this.groupData.groupName,
        groupEmail: this.groupData.groupEmail,
        members: this.memberFields
      }
      try {
        await this.createGroup({ _id, payload })
        this.dialog = false
        this.reset()
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style>
.vdialognew {
  box-shadow: none !important;
}
</style>
